
































import Vue from 'vue'

import InputText from '@/components/InputText.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default Vue.extend({
	name: 'FormAccountName',
	components: {
		InputText,
		LoadingSpinner,
	},
	props: {
		user: Object as Collection,
	},
	data: () : {
		fields: Collection,
		success: string,
	} => ({
		fields: {
			displayName: '',
		},
		success: '',
	}),
	computed: {
		loading(): boolean {
			return this.$store.getters['misc/loading']
		},
		error(): string {
			return this.$store.getters['misc/error']
		},
	},
	mounted() {
		this.fields.displayName = (this.user as Collection).displayName
	},
	methods: {
		submitForm(fields: Collection): void {
			this.$store.dispatch('user/updateProfile', fields)
				.then((success: string) => {
					this.success = success
				})
		},
	},
})

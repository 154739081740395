












































import Vue from 'vue'
import { Store } from 'vuex'
import { Route } from 'vue-router'
import parse from 'date-fns/parse'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'

import Card from '@/components/Card.vue'
import FormAccountPassword from '@/components/FormAccountPassword.vue'
import FormAccountName from '@/components/FormAccountName.vue'
import DrChronoAccount from '@/components/providers/DrChronoAccount.vue'

export default Vue.extend({
	name: 'PartialAccount',
	components: {
		Card,
		FormAccountPassword,
		FormAccountName,
		DrChronoAccount,
	},
	asyncData: (store: Store<any>, route: Route): Promise<any> => {
		return store.dispatch('user/loadUser')
	},
	computed: {
		profile(): Collection {
			return this.$store.getters['user/profile']
		},
		provider(): Collection {
			return this.$store.getters['user/provider']
		},
	},
	filters: {
		age(val: string): string {
			const parsed = new Date(val)
			try {
				val = formatDistanceStrict(
					parsed,
					(new Date()),
					{ addSuffix: true }
				)
			} catch (error) {

			}
			return val
		},
	},
	metaInfo: () => ({
		title: `Account`,
	})
})

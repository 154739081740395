











import Vue from 'vue'

export default Vue.extend({
	name: 'ProviderDrChrono',
	async beforeMount() {
		const { code } = this.$route.query

		if (code) {
			const provider = this.$store.getters['user/provider']
			if (provider) {
				switch( provider.key) {
					case 'drchrono':
						let res
						try {
							res = await this.$store.dispatch(`drchrono/auth`, code)
						} catch (error) {
							this.$notice(`ERROR: ${error.message || error}`)
						}

						await this.$store.dispatch('drchrono/login', res)
						this.$router.replace({ name: 'account', query: {}})
						break
				}
			}
		}
	},
	computed: {
		loginUrl(): string {
			let url: string = ''
			const provider = this.$store.getters['user/provider']
			const { origin, pathname } = window.location
			const redirectUrl = [origin, pathname].join('')

			if (provider) {
				switch( provider.key) {
					case 'drchrono':
						url = `https://drchrono.com/o/authorize/?redirect_uri=${redirectUrl}&response_type=code&client_id=${provider.client_id}&scope=patients:read%20patients:write%20clinical:read%20clinical:write%20user:read%20user:write%20billing:patient-payment:read%20billing:patient-payment:write%20billing:read%20billing:write`
						break
				}
			}

			return url
		},
		authToken(): string {
			return this.$store.getters['drchrono/token']
		},
	}
})

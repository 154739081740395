




























import Vue from 'vue'

import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default Vue.extend({
	name: 'FormAccountPassword',
	components: {
		LoadingSpinner,
	},
	props: {
		user: Object as Collection,
	},
	data: () : {
		success: string,
	} => ({
		success: ''
	}),
	computed: {
		loading(): boolean {
			return this.$store.getters['misc/loading']
		},
		error(): string {
			return this.$store.getters['misc/error']
		},
	},
	methods: {
		submitForm() {
			this.$store.dispatch('user/passwordReset', this.user)
				.then((success: string) => {
					this.success = success
				})
		},
	},
})
